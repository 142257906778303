export function fetchLoggedInUserOrders(userId) {
  return new Promise(async (resolve) => {
    const response = await fetch(
      "https://yunica.co/v1/orders/own?id=" + userId
    );
    const data = await response.json();
    resolve({ data });
  });
}

export function fetchLoggedInUser(userId) {
  return new Promise(async (resolve) => {
    const response = await fetch("https://yunica.co/v1/users/" + userId);
    const data = await response.json();
    resolve({ data });
  });
}

export function updateUser(update) {
  const { userId, addresses } = update;

  return new Promise(async (resolve) => {
    const response = await fetch("https://yunica.co/v1/users/" + userId, {
      method: "PATCH",
      body: JSON.stringify(addresses),
      headers: { "content-type": "application/json" },
    });
    const data = await response.json();
    // TODO: on server it will only return some info of user (not password)
    resolve({ data });
  });
}
